<template>
  <div>
    <div class="content">
      <div class="box_title">
        <div class="left">
          <span class="font28 color33">报考中心</span>
          <span class="font14 color66">Registration Center</span>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- <div class="screen_box">
        <li
          @click="changeStatus(item)"
          v-for="item in firstList"
          :key="item.id"
          :class="item.id == changeFirstItem.id ? 'active' : ''"
        >
          {{ item.name }}
        </li>
      </div> -->
      <div class="sellect_box">
        <el-cascader
          clearable
          :props="props"
          style="width: 210px; margin-right: 30px"
          @change="handleChange"
          placeholder="请选择省市区"
        >
        </el-cascader>
        <el-cascader
          clearable
          @change="handleCategory"
          :options="categoryList"
          :props="{
            children: 'childList',
            value: 'id',
            label: 'name',
          }"
          placeholder="请选择工种分类"
        >
          <template slot-scope="{data}">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </div>
    </div>

    <div class="my_list padding100" v-if="list.length != 0">
      <list
        :list="list.slice((curPage - 1) * pagesize, curPage * pagesize)"
        @getDataList="getDataList"
      ></list>
      <div class="pagination_box">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="list.length"
          :page-size="pagesize"
          :current-page="curPage"
          :hide-on-single-page="true"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
    <div class="empty" v-else>
      <div>
        <img src="../../assets/empty_box.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import list from "@/components/list.vue";
import {
  planList,
  getCityList,
  getAreaList,
  getProvinceList,
  searchCategory,
  getProvinceListByCompany,
  getCityListByCompany,
  getAreaListByCompany,
} from "@/util/api";
export default {
  components: {
    list,
  },
  data() {
    return {
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const {level} = node;
          setTimeout(() => {
            if (node.level == 0) {
              // Ajax請求數據，填充選擇框
              getProvinceListByCompany({
                companyId: window.sessionStorage.getItem("companyId"),
              }).then((res) => {
                const nodes = res.data.map((item, index) => ({
                  value: item.provinceCode,
                  label: item.province,
                  code: item.provinceCode,
                  leaf: node.level >= 2,
                }));
                resolve(nodes);
              });
            }
            // 第⼆級
            if (node.level == 1) {
              // Ajax請求數據，填充選擇框，傳遞上⼀級參數
              getCityListByCompany({
                provinceCode: node.value,
                companyId: window.sessionStorage.getItem("companyId"),
              }).then((res) => {
                const nodes = res.data.map((item, index) => ({
                  value: item.cityCode,
                  label: item.city,
                  code: item.cityCode,
                  leaf: node.level >= 2,
                }));
                resolve(nodes);
              });
            }
            if (node.level == 2) {
              // Ajax請求數據，填充選擇框，傳遞上⼀級參數
              getAreaListByCompany({
                cityCode: node.value,
                companyId: window.sessionStorage.getItem("companyId"),
              }).then((res) => {
                const nodes = res.data.map((item, index) => ({
                  value: item.areaCode,
                  label: item.area,
                  code: item.areaCode,
                  leaf: node.level >= 2,
                }));
                resolve(nodes);
              });
            }
          }, 100);
        },
      },
      query: {
        status: 0,
        categoryId: "",
        cityCode: "",
        areaCode: "",
        provinceCode: "",
        domainUrl: window.location.hostname,
        keyword: null,
      },
      pagesize: 10,
      curPage: 1,
      tableDota: [],
      dataShow: [], //当前要显示的数据
      firstList: [
        {
          id: "",
          name: "全部状态",
        },
        {
          id: "0",
          name: "报名中",
        },
        {
          id: "1",
          name: "已截止",
        },
      ],

      changeFirstItem: {
        id: "",
        name: "全部状态",
      },
      categoryList: [],
      list: [],
    };
  },
  mounted() {
    searchCategory({domainUrl: window.location.hostname}).then((res) => {
      this.categoryList = this.getTreeData(res.data);
    });
    this.getDataList();
    console.log(this.userInfo);
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    getDataList() {
      planList(this.query).then((res) => {
        this.list = res.data;
        for (let i in this.list) {
          this.list[i].enrollEndTime = this.list[i].enrollEndTime.replace(
            /\-/g,
            "/"
          );
          this.list[i].enrollStartTime = this.list[i].enrollStartTime.replace(
            /\-/g,
            "/"
          );
          if (this.list[i].areaName) {
            this.list[i].areaName = this.list[i].areaName.split(",");
          }
          // console.log(this.list[i].areaName);
        }
      });
    },
    handleChange(value) {
      this.query.provinceCode = value[0];
      this.query.cityCode = value[1];
      this.query.areaCode = value[2];
      this.curPage = 1;
      this.getDataList();
    },
    changeStatus(item) {
      this.changeFirstItem = item;
      this.query.status = item.id;
      this.curPage = 1;
      this.getDataList();
    },
    changePage(val) {
      this.curPage = val;
      // this.getDataList();
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].childList.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].childList = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].childList);
        }
      }
      return data;
    },
    handleCategory(e) {
      this.query.categoryId = e[2];
      this.curPage = 1;
      this.getDataList();
    },
  },
};
</script>
<style scoped>
.my_list >>> .web .item {
  padding: 30px 30px 30px;
  border: none;
  width: calc(1200px - 60px);
  margin: 0 auto 20px;
}
</style>
<style lang="scss" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
}
.box_title {
  padding: 40px 0 0;
}
.screen_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 21px;
  li {
    padding: 7px 20px;
    border-radius: 10px;
    font-size: 14px;
    color: #333;
    margin-right: 22px;
    list-style: none;
    cursor: pointer;
  }
  li.active {
    color: #2b68d9;
    background-color: #f5f5f5;
  }
  .search_input {
    width: 320px;
    display: flex;
    margin-left: 100px;
    button {
      margin: 0 10px;
      background-color: #515a6e;
      border: none;
    }
  }
}
.sellect_box {
  margin: 20px 0;

  .el-select {
    margin-right: 30px;
  }
}
.my_list {
  background-color: #f5f5f5;
  width: 100%;
  padding: 30px 0 30px;
  .box {
    width: 1200px;
    margin: 0 auto;
  }
}
.pagination_box {
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  width: 950px;
  margin: 0 auto;
  img {
    width: 400px;
    height: 400px;
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
}
</style>
